import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { Layout } from '../components/Layout'
import { Head } from '../components/Head'
import { PageTitle } from '../components/PageTitle'
import { ChangeColorBarThirdParty } from '../components/ChangeColorBarThirdParty'
import flowChart from '../images/ThirdPartySuccessionImage.png'
import { AddContentsCard } from '../components/AddContentsCard.js'
import { AddContentsCard2 } from '../components/AddContentsCard2'


const sectionContainer = css`
  width: 100%;
  margin: 0 auto;
  padding: 80px;
  ${mediaQuery[smartPhone]} {
    width: 90%;
    padding: 40px;
  }
`

const container = css`
  display: flex;
  flex-direction: row;
`

const sectionTitle = css`
  font-family: ${font.TitleFontFamily};
  font-size: ${font.subtitleSize};
  color: ${color.black};
  font-weight: Bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
`

const text = css`
  width: 100%;
  padding-top: 16px;
  font-size: ${font.textSize};
  ${mediaQuery[smartPhone]} {
    width: 95%;
  }
`

const imageContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const imageContainer2 = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`

const image = css`
  width: 80%;
  ${mediaQuery[smartPhone]} {
    width: 95%;
  }
`
const flexTop = css`
  width: 80%;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  ${mediaQuery[smartPhone]} {
    display: block;
    width: 90%;
  }
`



export default function ThirdPartySuccession({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const title = 'Third-party succession'
  const subTitle = '第三者継承'
  const [pathname, setPathname] = useState('')
  useEffect(() => {
    setPathname(location.pathname)
  }, [])

  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <PageTitle title={[title, subTitle]} />
      <div css={container}>
        <section css={sectionContainer}>
          <div>
            <h3 css={sectionTitle}>医院継承‧M＆Aご契約の流れ</h3>
            <ChangeColorBarThirdParty />
          </div>
          <p css={text}>
            当組合では譲渡したい方・譲り受けたい方、どちらについてもアドバイザー業務を行っております。
          </p>
          <p css={text}>ご不明な点がございましたらお気軽にご相談ください。</p>
        </section>
      </div>
      <div css={imageContainer2}>
        <img css={image} src={flowChart} alt="譲渡の流れ" />
      </div>
      <div css={flexTop}>
        <AddContentsCard
          mainText1="新規開業・事業承継を"
          mainText2="お考えの方はこちら"
          subText="今後、奈良県でのご開業に興味のある方は、こちらから情報配信登録をしていただきますと、開業に関する情報などをメールにて優先的に配信させていただきます。是非ご活用ください。"
          link="https://forms.gle/A4kJfHRyKdqJuQhx6"
          btnText="開業サポート登録"
        />
        <AddContentsCard2
          mainText1="事業譲渡を"
          mainText2="お考えの方はこちら"
          subText="今後引退をお考えで後継者の先生が決まっていない場合など是非お気軽にご相談ください。こちらからサポート登録していただくことでご相談や事業継承に関するサポートをさせていただきます。"
          link="https://forms.gle/rVyki2mz5wSzYZ7D9"
          btnText="事業継承サポート登録"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`
